<template>
    <SbPage :blok="blok" />
    <component :is="modal" v-if="modal" :global="globalStore.global.content" :blok="modalContent" />
</template>

<script setup>
import { useGlobalStore } from '../../_base/store/global';
import { useModalStore } from '../../_base/store/modal';

defineProps({ blok: Object });

// Constant variable for access to quote store
const globalStore = useGlobalStore();
const modalStore = useModalStore();

const modal = computed(() => {
    if (modalStore.SignIn) {
        return defineAsyncComponent(() => import(`@/storyblok/SignInModal.vue`));
    } else if (modalStore.SignUp) {
        return defineAsyncComponent(() => import(`@/storyblok/SignUpModal.vue`));
    } else if (modalStore.ResetPassword) {
        return defineAsyncComponent(() => import(`@/storyblok/ResetPasswordModal.vue`));
    }
});

const modalContent = computed(() => {
    if (modalStore.SignIn) {
        return globalStore.signInModal;
    } else if (modalStore.SignUp) {
        return globalStore.signUpModal;
    } else if (modalStore.ResetPassword) {
        return globalStore.resetPasswordModal;
    }
});

await $getSession();
</script>
